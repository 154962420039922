import { Component, OnInit } from '@angular/core';
import {WfhService} from '../service/wfh.service';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-datadurasi',
  templateUrl: './datadurasi.component.html',
  styleUrls: ['./datadurasi.component.css']
})
export class DatadurasiComponent implements OnInit {
  data:any=[];durasi:any=[]; datas:any=[];datanamauser:any;
  namauser: any;
  no:any=[];
  constructor(private wfh:WfhService) { }

  ngOnInit(): void {
    this.datawfhview();
    this.no=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
  }

  datawfhview(){
    this.wfh.getalluser().subscribe(datanamauser=>{
      console.log(datanamauser);
      this.datanamauser=datanamauser
      this.durasiwfhview();

      })};
  
  durasiwfhview(){
  
    this.wfh.allDurasi().subscribe(durasi=>{
      console.log(durasi);
  this.durasi=durasi;
  
  })};



}
