<div class="container">
  <div class="card-body">
<nav class="navbar navbar-expand-md navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a routerLink ="/home" class="nav-link  text-primary" >BERANDA</a>
      </li>
      <li class="nav-item">
        <a routerLink ="/inputuser" class="nav-link text-primary" >DATA USER</a>
      </li>
      <li class="nav-item">
        <a routerLink ="/durasi" class="nav-link text-primary" >DURASI WFH</a>
      </li>
      <li> <a routerLink ="/home" (click)="logout()" class="nav-link text-danger" >LOGOUT</a></li>
    </ul>
  </nav>
  </div>
  </div>
      
           
  <div class="container"    >
    <div class="card">
     
      <div class="text-center">
          <h1>Laporan WFH</h1>
      <h2>SMA ADZKIA ISLAMIC SCHOOL</h2>
      </div>
          </div>
       </div>
  <div class="container">
    <div class="card">
      <div class="col-md-12 table-responsive">
        <div style="padding-bottom: 10px;padding-top: 10px;">
         <div class="row">
          <div class="col-sm-12">
           <button (click)="exportToExcel()" class="btn btn-success">Export To Excel</button> 
          </div>
         </div>
        </div>
      <div class="table-responsive">
       
      <table class="table  mx-auto w-auto" #download id="download">
        <tr>
          <td class="text-center" colspan="8"><h2>Laporan WFH</h2></td>
        </tr>
        <tr><td colspan="8">Nama Santri : {{namauser}}</td></tr>
        <tr *ngFor="let users of user| filter:namauser "><td colspan="8" >Jenis Karya : {{users.karya}}</td></tr>

        <ng-container *ngFor = "let datadurasi of durasi| orderBy :['created_at']">
         <div>
          <thead>
              <tr>
                  <td class="text-left"colspan="8"> {{datadurasi.tgl }} \ {{datadurasi.bulan}} \ 2021</td>
              </tr>
              <tr>
              <td>No</td>
              <td>Waktu Mulai</td>
              <td>Waktu Selesai</td>
              <td>Durasi</td>
              <td>aktifitas</td>       
               <td>progres</td>
              <td>tindak lanjut</td>
              <td>lampiran</td>
              <td></td>
          </tr>
          </thead>
             <tr  *ngFor = "let datas of data| orderBy :['created_at'] | filterBy: ['tgl']: datadurasi.tgl ; let i = index">
              
              <td>{{i+1}}</td>
              <td>' {{datas.waktu_mulai }}</td>
              <td>' {{datas.waktu_selesai }}</td>
              <td>{{datas.durasi}}</td>
              <td>{{datas.aktifitas}}</td>
              <td>{{datas.progres}}</td>
              <td>{{datas.tindak_lanjut}}</td>
              <td>{{datas.lampiran}}</td>
              <td><a (click)="tambah(datas)" class="button"><fa-icon [icon]="faBookmark " class="fa-lg" style="color:#018813"></fa-icon></a></td>
              <td><a data-toggle="modal"(click)="modal(datas)"
                data-target="#myModal" class="button"><fa-icon [icon]="faTimes " class="fa-lg" style="color:#b4060f"></fa-icon></a></td>

            </tr>
          <tr >
              <td colspan="3"><b>Jumlah durasi</b></td>
              
              <td>{{datadurasi.durasi}}  </td>
              <td colspan="4">

              </td>
             
          </tr>
        </div>
        </ng-container>
      </table>
      </div>
      </div>
      </div>
    </div>
      <!-- Button trigger modal -->
        <!-- Modal -->
  <div class="modal fade" id="myModal" role="dialog">
    <div class="modal-dialog">
    
      <!-- Modal content-->
      <div class="modal-content">
       
        <div class="modal-body">
          <p>Yakin Mau Menghapus Data Ini</p>
        </div>
        <div class="modal-footer">
          <button type="button"  class="btn btn-default" data-dismiss="modal">Close</button>

          <button type="button" (click)="kurang()" class="btn btn-default" data-dismiss="modal">Hapus</button>
        </div>
      </div>
      
    </div>
  </div>
      
