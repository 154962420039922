import { Component, OnInit } from '@angular/core';
import {WfhService} from '../service/wfh.service';
import { CookieService } from 'ngx-cookie-service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
data:any=[]; user:any=[];periode:any=[]; 
  alluser: any=[];
  groupdata: any=[];
  result:any=[];
  constructor(private router:Router, private wfh:WfhService, private cookieService: CookieService) { }

  ngOnInit(): void {
    this.gperiode();
    this.getuser();
  }
  gperiode(){
    this.wfh.getTgl().subscribe(periode=>{
      console.log(periode);
     this.periode=periode;
    
    });
  }
getuser(){
  this.wfh.getalluser().subscribe(user=>{
    this.alluser=user;
  })
}
  datawfhview(){this.wfh.getallData().subscribe(data=>{
     this.data = data
      })};

      logout(){
        this.cookieService.deleteAll();
       this.router.navigate(['/'])
      }

}
