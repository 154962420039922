import { Component, OnInit } from '@angular/core';
import {WfhService} from '../service/wfh.service';
import { CookieService } from 'ngx-cookie-service';
import {Router} from "@angular/router";
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
nama:any;
username:any;
password:any;
akses:any;
  data: any;
  pesan: any;
  periode:any=[];
  idperiode: any;
  blnperiode: any;
  karya: any;
  user: any;
  response: any;
  id: any;

  constructor(private router:Router, private wfh:WfhService, private cookieService:CookieService) { }
 
  ngOnInit(): void {
    this.getuser();
    this.gperiode2();
    
  }
saveuser(){
  const user = {
    nama:this.nama,
    username:this.username,
    password:this.password,
    akses:this.akses,
    karya:this.karya

  };
  this.wfh.saveuser(user).subscribe(data=>{
    console.log(data);
      data = this.data;
      this.pesan ="Berhasil tersimpan";
      this.getuser();
    },Error=>{
      this.pesan=Error.error.message
     
  });
  }

  hapusdata(users:any){
    this.wfh.hapusUser(users.id).subscribe(Response=>{
    this.getuser();
    console.log(Response);
    });
    }

  getuser(){
    this.wfh.getalluser().subscribe(user=>{
      this.user=user
    });
  }

  gperiode2(){
    this.wfh.getperiode().subscribe(periode=>{
      this.periode=periode
      
    });
  }
  updateperiode(periode: { [x: string]: any; }[]){
    this.id = periode[0]['id'];
    const periodeupdate:any = {
      id:this.id,
      idperiode:this.idperiode,
      blnperiode:this.blnperiode,}
  
      this.wfh.updateperiode(periodeupdate).subscribe(per=>{
        console.log(per);
        this.gperiode2();
      })
    };

    logout(){
      this.cookieService.deleteAll();
     this.router.navigate(['/'])
    }
  }

