import { Component, OnInit, Input,ViewChild, ElementRef } from '@angular/core';
import {WfhService} from '../service/wfh.service';
import { DataServiceService} from '../service/data-service.service';
import { CookieService } from 'ngx-cookie-service';
import {Router, ActivatedRoute} from "@angular/router";
import { faCheckCircle,faTimes } from '@fortawesome/free-solid-svg-icons';

import * as xlsx from 'xlsx';
@Component({
  selector: 'app-kabagadmin',
  templateUrl: './kabagadmin.component.html',
  styleUrls: ['./kabagadmin.component.css']
})
export class KabagadminComponent implements OnInit {
  faBookmark = faCheckCircle;faTimes=faTimes;

  @ViewChild('epltable', { static: false })
  epltable!: ElementRef;
  data:any=[];
  durasi: any;
@Input() namauser:any;
  user: any;
  id: any;
  waktu_selesai: any;
  tglupdate: any;
  blnupdate: any;
  selesaiupdate: any;
  mulaiupdate: any;
  durasiupdate: any;
  aktifitasupdate: any;
  lampiranupdate: any;
  tindakupdate: any;
  progresupdate: any;
  tersimpan:boolean=true;
  message: any;
  
  constructor(private dataservice:DataServiceService, private route:ActivatedRoute, private router: Router ,private wfh:WfhService, private cookieService: CookieService) { }

  ngOnInit(): void {
    
    this.route.paramMap.subscribe( params =>
      this.namauser = params.get('nama'));
    this.datawfhview(); 
    this.durasiwfhview();
    this. getUser()
  }

  getUser(){
    this.dataservice.getalluser().subscribe(user=>{
      console.log(user)
      this.user=user
    });
  }
  datawfhview(){
    const namauser ={nama:this.namauser};
    this.wfh.getData(namauser).subscribe(data=>{
    console.log(data),
      this.data=data
      })};
  
  durasiwfhview(){
    const namauser ={nama:this.namauser};
  
    this.wfh.getDurasi(namauser).subscribe(durasi=>{
  
  this.durasi=durasi;
  
  })};

  logout(){
    this.cookieService.deleteAll();
   this.router.navigate(['/'])
  }

  exportToExcel() {
  const ws: xlsx.WorkSheet =   
  xlsx.utils.table_to_sheet(this.epltable.nativeElement);
  const wb: xlsx.WorkBook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  xlsx.writeFile(wb, 'epltable.xlsx');
 }
 modal(datas:any){
  this.id=datas.id;
}
kurang(){

    this.wfh.hapusData(this.id).subscribe(Response=>{
    this.datawfhview();
    this.durasiwfhview();
    })
     }
     tambah(datas: any){
      this.id = datas.id;
      this.tglupdate = datas.tgl;
      this.blnupdate = datas.bulan;
      this.selesaiupdate = datas.waktu_selesai;
      this.mulaiupdate = datas.waktu_mulai;
      this.durasiupdate = datas.durasi;
      this.aktifitasupdate = datas.aktifitas;
      this.lampiranupdate = datas.lampiran;
      this.tindakupdate = datas.tindak_lanjut;
      this.progresupdate = datas.progres;
    }
    updatewfh(){
      const datawfh={
        nama:this.namauser,
       tgl: this.tglupdate,
       bulan: this.blnupdate,
       waktu_selesai: this.selesaiupdate,
       waktu_mulai: this.mulaiupdate,
       durasi: this.durasiupdate,
       aktifitas: this.aktifitasupdate,
       lampiran: this.lampiranupdate,
       tindak_lanjut: this.tindakupdate,
       progres: this.progresupdate,
      }
      
      this.wfh.updateData(this.id,datawfh).subscribe(data=>{
        console.log(data)
        this.message = data['message']; 
        this.datawfhview();
        this.durasiwfhview();
        setTimeout( () => {
          console.log('hide');
          this.message = ""; 
        }, 2000);
      });
      }
}
