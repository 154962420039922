import { Component, OnInit, Inject } from '@angular/core';
import {WfhService} from '../service/wfh.service';
import {Router} from "@angular/router"
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
username:any; datalogin:any=[];error:any;
password:any; data:any; pesan:any;
periode:any=[];
  idperiode: void;
  blnperiode: any;
  id: any;
  constructor(private wfh:WfhService , private router: Router , private cookieService: CookieService) { }

  ngOnInit(): void {
    this.gperiode();
  }
  gperiode(){
    this.wfh.getperiode().subscribe(periode=>{
      this.cookieService.set('periode',periode[0]['periode'])
    })
  }
  
login(){
const datalogin={
  username : this.username,
  password : this.password
}
this.wfh.loginUser(datalogin).subscribe(data=>{
  console.log(data.result.nama);
    if(data.result.akses ==="superadmin"){
  this.cookieService.set('nama', data.result.nama);
  this.cookieService.set('token', data.result.token);
  this.gperiode();
  this.router.navigate(['/home'])
  }else if(data.result.akses ==="kabag") {
   
    this.cookieService.set('nama', data.result.nama);
    this.cookieService.set('token', data.result.token);
    this.gperiode();
    this.router.navigate(['/kabag'])
  }else{
    
    this.cookieService.set('nama', data.result.nama);
    this.cookieService.set('token', data.result.token);
    this.gperiode();
    this.router.navigate(['/datawfh'])
  }}, error => { 
    this.error=error.error.error;
  console.log(error.error.error)

  })
}

}