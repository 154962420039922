<div class="container"    >
  <div class="card">
   
    <div class="text-center">
        <h1>Laporan WFH</h1>
    <h2>SMA ADZKIA ISLAMIC SCHOOL</h2>
    </div>
        </div>
     </div>
<div class="container">
  <div class="card">
    <div class="col-md-12 table-responsive">
      <div style="padding-bottom: 10px;padding-top: 10px;">
       <div class="row">
        <div class="col-sm-12">
         <button (click)="exportToExcel()" class="btn btn-success">Export To Excel</button> 
        </div>
       </div>
      </div>
    <div class="table-responsive">
     
    <table class="table  mx-auto w-auto" #download id="download">
      <tr>
        <td class="text-center" colspan="8"><h2>Laporan WFH</h2></td>
      </tr>
      <tr><td colspan="8">Nama Santri : {{namauser}}</td></tr>
      <tr *ngFor="let users of user| filter:namauser "><td colspan="8" >Jenis Karya : {{users.karya}}</td></tr>

      <ng-container *ngFor = "let datadurasi of durasi| orderBy :['created_at']">
       <div>
        <thead>
            <tr>
                <td class="text-left"colspan="8"> {{datadurasi.tgl }}  {{datadurasi.bulan}}  2021</td>
            </tr>
            <tr>
            <td>No</td>
            <td>Waktu Mulai</td>
            <td>Waktu Selesai</td>
            <td>Durasi</td>
            <td>aktifitas</td>       
             <td>progres</td>
            <td>tindak lanjut</td>
            <td>lampiran</td>
            <td></td>
        </tr>
        </thead>
           <tr  *ngFor = "let datas of data| orderBy :['created_at'] | filterBy: ['tgl']: datadurasi.tgl ; let i = index">
            
            <td>{{i+1}}</td>
            <td> {{datas.waktu_mulai }}</td>
            <td> {{datas.waktu_selesai }}</td>
            <td>{{datas.durasi}}</td>
            <td>{{datas.aktifitas}}</td>
            <td>{{datas.progres}}</td>
            <td>{{datas.tindak_lanjut}}</td>
            <td>{{datas.lampiran}}</td>
            <td><a (click)="tambah(datas)"  data-toggle="modal" data-target="#updateModal" class="button"><fa-icon [icon]="faBookmark " class="fa-lg" style="color:#018813"></fa-icon></a></td>
            <td><a data-toggle="modal"(click)="modal(datas)"
              data-target="#myModal" class="button"><fa-icon [icon]="faTimes " class="fa-lg" style="color:#b4060f"></fa-icon></a></td>

          </tr>
        <tr >
            <td colspan="3"><b>Jumlah durasi</b></td>
            
            <td>{{datadurasi.durasi}}  </td>
            <td colspan="4">

            </td>
           
        </tr>
      </div>
      </ng-container>
    </table>
    </div>
    </div>
    </div>
  </div>
    <!-- Button trigger modal -->
      <!-- Modal -->
<div class="modal fade" id="myModal" role="dialog">
  <div class="modal-dialog">
  
    <!-- Modal content-->
    <div class="modal-content">
     
      <div class="modal-body">
        <p>Yakin Mau Menghapus Data Ini</p>
      </div>
      <div class="modal-footer">
        <button type="button"  class="btn btn-default" data-dismiss="modal">Close</button>

        <button type="button" (click)="kurang()" class="btn btn-default" data-dismiss="modal">Hapus</button>
      </div>
    </div>
    
  </div>
</div>
<div
  class="modal fade"
  id="updateModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="updateModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateModalLabel">UPDATE DATA</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="sel1">Update Data  {{tglupdate}} {{blnupdate}}</label>
          
        </div>
          <div class="form-group">
          <label for="usr">Waktu Mulai:</label>
          <input
            [(ngModel)]="mulaiupdate"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Waktu Selesai:</label>
          <input
            [(ngModel)]="selesaiupdate"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Durasi:</label>
          <input
            [(ngModel)]="durasiupdate"
            type="number"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Aktifitas:</label>
          <input
            [(ngModel)]="aktifitasupdate"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Progres:</label>
          <input
            [(ngModel)]="progresupdate"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Tindak Lanjut:</label>
          <input
            [(ngModel)]="tindakupdate"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Lampiran:</label>
          <input
            [(ngModel)]="lampiranupdate"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
      </div>
      <div class="modal-footer">
        {{message}}
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button (click)="updatewfh()" type="button" class="btn btn-danger">
          Update
        </button>
      </div>
    </div>
  </div>
</div>