import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable,throwError} from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
const baseUrl = 'http://apiwfh.smaadzkiadt.sch.id/public/api';
const baseUrlUser ='http://apiwfh.smaadzkiadt.sch.id/public/api/user';

const baseUrlwfh = 'http://apiwfh.smaadzkiadt.sch.id/public/api/getdata';
const baseUrldurasi = 'http://apiwfh.smaadzkiadt.sch.id/public/api/durasi';
@Injectable({
  providedIn: 'root'
})
export class WfhService {

  constructor(private http: HttpClient, private cookieService:CookieService ) { }
  getalluser(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'/user',{params});
  }
  getallData(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get('baseUrl',{params});
  }
  getData(namauser:any): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(`${baseUrlwfh}/${namauser.nama}`,{params});
  }
  getDurasi(namauser:any): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(`${baseUrldurasi}/${namauser.nama}`,{params});
  }
  allDurasi(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrldurasi,{params});
  }
  postData(datapostwfh:any): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.post(baseUrlwfh,datapostwfh,{params});
  }
  hapusData(id: string):Observable<any>{
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.delete(`${baseUrlwfh}/${id}`,{params});
  }
  updateData(id: string,datapostwfh:any):Observable<any>{
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.post(`${baseUrlwfh}/${id}`,datapostwfh,{params});
  }

  hapusUser(id: string):Observable<any>{
    const params = new HttpParams().append('token',this.cookieService.get('token'));
    return this.http.delete(`${baseUrlUser}/${id}`,{params});
  }
  loginUser(datalogin:any):Observable<any>{
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.post(baseUrl+'/login',datalogin,{params});
  }
  postTgl(datatg:any):Observable<any>{
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    
    return this.http.post(baseUrl+'/tgl',datatg,{params});
}
getTgl():Observable<any>{
  const params = new HttpParams().append('token',this.cookieService.get('token'));

  return this.http.get(baseUrldurasi,{params});
}
updateperiode(periodeupdate:any):Observable<any>{
  const params = new HttpParams().append('token',this.cookieService.get('token'));

  return this.http.post(baseUrl+'/periode',periodeupdate,{params});
}
getperiode():Observable<any>{
  const params = new HttpParams().append('token',this.cookieService.get('token'));

  return this.http.get(baseUrl+'/periode',{params});
}
saveuser(user:any):Observable<any>{
  const params = new HttpParams().append('token',this.cookieService.get('token'));

  return this.http.post(baseUrl+'/register',user,{params})
}
}
