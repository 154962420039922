<div class="container">
  <div class="card-body">
<nav class="navbar navbar-expand-md navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a routerLink ="/home" class="nav-link  text-primary" >BERANDA</a>
      </li>
      <li class="nav-item">
        <a routerLink ="/inputuser" class="nav-link text-primary" >DATA USER</a>
      </li>
      <li class="nav-item">
        <a routerLink ="/durasi" class="nav-link text-primary" >DURASI WFH</a>
      </li>
      <li> <a routerLink ="/home" (click)="logout()" class="nav-link text-danger" >LOGOUT</a></li>
    </ul>
  </nav>
  </div>
  </div>
  <div class="container">
  <div class="card">
    <div class="card-header">
   <b> Data Harian WFH </b>
      </div>
    <div class="card-body">
      <div class="table-responsive">
   <table class="table-striped">
     
      <tbody>
        <ng-container *ngFor="let users of alluser">
        <tr>
          <td >     <a routerLink="/inputdata/{{users.nama}}" routerLinkActive="active">{{users.nama}}</a> 
          <td *ngFor = "let periodes of periode| filter:users.nama">   {{periodes.tgl}}   </td>
        </tr>
      </ng-container>
       
      </tbody>
   </table>
   </div>
   </div>
   </div>
  </div>