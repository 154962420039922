<div class="container my-1">
  <button
    type="button"
    class="btn btn-primary"
    data-toggle="modal"
    data-target="#exampleModal"
  >
    Input WFH
  </button>
  <button type="button" class="btn btn-danger float-right" (click)="logout()">
    logout
  </button>
</div>
<div class="container">
  <div class="card">
    <div class="card-header text-center">
      <b>Laporan WFH </b><br />
      <b>SMA ADZKIA ISLAMIC SCHOOL</b>
      <p>Selamat datang {{ namauser }}</p>
    </div>

    <div class="card-body">
      <div class="table-responsive">
        <table class="table mx-auto w-auto">
          <ng-container
            *ngFor="let datadurasi of durasi | orderBy: ['created_at']"
          >
            <div>
              <thead>
                <tr>
                  <td colspan="9">
                    Laporan WFH tanggal {{ datadurasi.tgl }}
                    {{ datadurasi.bulan }}
                  </td>
                </tr>
                <tr>
                  <td>No</td>
                  <td>Waktu Mulai</td>
                  <td>Waktu Selesai</td>
                  <td>Durasi</td>
                  <td>aktifitas</td>
                  <td>progres</td>
                  <td>tindak lanjut</td>
                  <td>lampiran</td>
                  <td></td>
                </tr>
              </thead>
              <tr
                *ngFor="
                  let datas of datanamauser
                    | orderBy: ['waktu_mulai']
                    | filterBy: ['tgl']:datadurasi.tgl;
                  let i = index
                "
              >
                <td>{{ i + 1 }}</td>
                <td>{{ datas.waktu_mulai }}</td>
                <td>{{ datas.waktu_selesai }}</td>
                <td>{{ datas.durasi }}</td>
                <td>{{ datas.aktifitas }}</td>
                <td>{{ datas.progres }}</td>
                <td>{{ datas.tindak_lanjut }}</td>
                <td>{{ datas.lampiran }}</td>
                <td>
                  <a (click)="updateData(datas)" class="button" 
                   data-toggle="modal"
                  data-target="#updateModal"
                    ><fa-icon
                      [icon]="faEdit"
                      class="fa-lg"
                      style="color: #018813"
                                          ></fa-icon
                  ></a>
                </td>
                <td>
                  <a (click)="hapusdata(datas)" class="button"
                    ><fa-icon
                      [icon]="faTimes"
                      class="fa-lg"
                      style="color: #b4060f"
                    ></fa-icon
                  ></a>
                </td>
              </tr>
              <tr>
                <td colspan="3"><b>Jumlah durasi</b></td>

                <td colspan="6">{{ datadurasi.durasi }}</td>
              </tr>
            </div>
          </ng-container>
        </table>
      </div>
    </div>
  </div>
  <!-- Button trigger modal -->
</div>
<!-- Modal -->
<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Input WFH</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="sel1">Tanggal:</label>
          <select [(ngModel)]="tglwfh" class="form-control" id="sel1">
            <option *ngFor="let numbers of number">{{ numbers }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="sel1">Bulan:</label>
          <select [(ngModel)]="bulanwfh" class="form-control" id="sel1">
            <option *ngFor="let bulans of bulan">{{ bulans }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="usr">Waktu Mulai:</label>
          <input
            [(ngModel)]="waktu_mulai"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Waktu Selesai:</label>
          <input
            [(ngModel)]="waktu_selesai"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Durasi:</label>
          <input
            [(ngModel)]="durasiwfh"
            type="number"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Aktifitas:</label>
          <input
            [(ngModel)]="aktifitas"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Progres:</label>
          <input
            [(ngModel)]="progres"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Tindak Lanjut:</label>
          <input
            [(ngModel)]="tindak_lanjut"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Lampiran:</label>
          <input
            [(ngModel)]="lampiran"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
      </div>
      <div class="modal-footer">
        <div class="text-success" *ngIf="tersimpan">{{ data }}</div>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button (click)="datawfh()" type="button" class="btn btn-danger">
          simpan
        </button>
      </div>
    </div>
  </div>
</div>
<!-- Modal -->
<div
  class="modal fade"
  id="updateModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="updateModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="updateModalLabel">UPDATE DATA</h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="sel1">Update Data  {{tglupdate}} {{blnupdate}}</label>
          
        </div>
          <div class="form-group">
          <label for="usr">Waktu Mulai:</label>
          <input
            [(ngModel)]="mulaiupdate"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Waktu Selesai:</label>
          <input
            [(ngModel)]="selesaiupdate"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Durasi:</label>
          <input
            [(ngModel)]="durasiupdate"
            type="number"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Aktifitas:</label>
          <input
            [(ngModel)]="aktifitasupdate"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Progres:</label>
          <input
            [(ngModel)]="progresupdate"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Tindak Lanjut:</label>
          <input
            [(ngModel)]="tindakupdate"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
        <div class="form-group">
          <label for="usr">Lampiran:</label>
          <input
            [(ngModel)]="lampiranupdate"
            type="text"
            class="form-control"
            id="usr"
          />
        </div>
      </div>
      <div class="modal-footer">
        <div class="text-success" *ngIf="tersimpan">{{ data }}</div>
        <button type="button" class="btn btn-secondary" data-dismiss="modal">
          Close
        </button>
        <button (click)="updatewfh()" type="button" class="btn btn-danger">
          Update
        </button>
      </div>
    </div>
  </div>
</div>
