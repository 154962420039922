import { Component, OnInit, Input,ViewChild, ElementRef } from '@angular/core';
import { DataServiceService} from '../service/data-service.service';
import { CookieService } from 'ngx-cookie-service';
import {Router} from "@angular/router";
import * as xlsx from 'xlsx';
@Component({
  selector: 'app-durasi',
  templateUrl: './durasi.component.html',
  styleUrls: ['./durasi.component.css']
})
export class DurasiComponent implements OnInit {
  @ViewChild('epltable', { static: false })
  epltable!: ElementRef;
  data: any;
  user: any;
  data19: any;
  data20: any;
  data21: any;
  data22: any;
  data24: any;
  data23: any;
  data25: any;
  data26: any;
  data27: any;
  data28: any;
  data29: any;
  data30: any;
  data31: any;
  data1: any;
  data2: any;
  data3: any;
  data4: any;
  data5: any;
  data6: any;
  data7: any;
  data8: any;
  data9: any;
  data10: any;
  data11: any;
  data12: any;
  data13: any;
  data14: any;
  data15: any;
  data16: any;
  data17: any;
  data18: any;
  
  



  constructor(private router:Router, private dataservice:DataServiceService, private cookieService: CookieService) { }

  ngOnInit(): void {
  this.durasi19();this.durasi9();
  this.durasi20();this.durasi10();
  this.durasi21();this.durasi11();
  this.durasi22();this.durasi12();
  this.durasi23();this.durasi13();
  this.durasi24();this.durasi14();
  this.durasi25();this.durasi15();
  this.durasi26();this.durasi16();
  this.durasi27();this.durasi17();
  this.durasi28();this.durasi18();
  this.durasi29();
  this.durasi30();
  this.durasi31();
  this.durasi1();
  this.durasi2();
  this.durasi3();
  this.durasi4();
  this.durasi5();
  this.durasi6();
  this.durasi7();
  this.durasi8();
  this.getUser()
}
getUser(){
  this.dataservice.getalluser().subscribe(user=>{
    console.log(user);
    this.user=user
  });
}
durasi19(){
  this.dataservice.getData19().subscribe(data19=>{
    console.log(data19);
    this.data19=data19
  });
}

durasi20(){
  this.dataservice.getData20().subscribe(data20=>{
    console.log(data20);
    this.data20=data20
  });
}
durasi21(){
  this.dataservice.getData21().subscribe(data21=>{
    console.log(data21);
    this.data21=data21
  });
}
durasi22(){
  this.dataservice.getData22().subscribe(data22=>{
    console.log(data22);
    this.data22=data22
  });
}
durasi24(){
  this.dataservice.getData24().subscribe(data24=>{
    console.log(data24);
    this.data24=data24
  });
}
durasi23(){
  this.dataservice.getData23().subscribe(data23=>{
    console.log(data23);
    this.data23=data23
  });
}
durasi25(){
  this.dataservice.getData25().subscribe(data25=>{
    console.log(data25);
    this.data25=data25
  });
}
durasi26(){
  this.dataservice.getData26().subscribe(data26=>{
    console.log(data26);
    this.data26=data26
  });
}
durasi27(){
  this.dataservice.getData27().subscribe(data27=>{
    console.log(data27);
    this.data27=data27
  });
}
durasi28(){
  this.dataservice.getData28().subscribe(data28=>{
    console.log(data28);
    this.data28=data28
  });
}
durasi29(){
  this.dataservice.getData29().subscribe(data29=>{
    console.log(data29);
    this.data29=data29
  });
}
durasi30(){
  this.dataservice.getData30().subscribe(data30=>{
    console.log(data30);
    this.data30=data30
  });
}
durasi31(){
  this.dataservice.getData31().subscribe(data31=>{
    console.log(data31);
    this.data31=data31
  });
}
durasi1(){
  this.dataservice.getData1().subscribe(data1=>{
    console.log(data1);
    this.data1=data1
  });
}
durasi2(){
  this.dataservice.getData2().subscribe(data2=>{
    console.log(data2);
    this.data2=data2
  });
}
durasi3(){
  this.dataservice.getData3().subscribe(data3=>{
    console.log(data3);
    this.data3=data3
  });
}
durasi4(){
  this.dataservice.getData4().subscribe(data4=>{
    console.log(data4);
    this.data4=data4
  });
}
durasi5(){
  this.dataservice.getData5().subscribe(data5=>{
    console.log(data5);
    this.data5=data5
  });
}
durasi6(){
  this.dataservice.getData6().subscribe(data6=>{
    console.log(data6);
    this.data6=data6
  });
}
durasi7(){
  this.dataservice.getData7().subscribe(data7=>{
    console.log(data7);
    this.data7=data7
  });
}
durasi8(){
  this.dataservice.getData8().subscribe(data8=>{
    console.log(data8);
    this.data8=data8
  });
}
durasi9(){
  this.dataservice.getData9().subscribe(data9=>{
    console.log(data9);
    this.data9=data9
  });
}
durasi10(){
  this.dataservice.getData10().subscribe(data10=>{
    console.log(data10);
    this.data10=data10
  });
}
durasi11(){
  this.dataservice.getData11().subscribe(data11=>{
    console.log(data11);
    this.data11=data11
  });
}
durasi12(){
  this.dataservice.getData12().subscribe(data12=>{
    console.log(data12);
    this.data12=data12
  });
}
durasi13(){
  this.dataservice.getData13().subscribe(data13=>{
    console.log(data13);
    this.data13=data13
  });
}
durasi14(){
  this.dataservice.getData14().subscribe(data14=>{
    console.log(data14);
    this.data14=data14
  });
}
durasi15(){
  this.dataservice.getData15().subscribe(data15=>{
    console.log(data15);
    this.data15=data15
  });
}
durasi16(){
  this.dataservice.getData16().subscribe(data16=>{
    console.log(data16);
    this.data16=data16
  });
}
durasi17(){
  this.dataservice.getData17().subscribe(data17=>{
    console.log(data17);
    this.data17=data17
  });
}
durasi18(){
  this.dataservice.getData18().subscribe(data18=>{
    console.log(data18);
    this.data18=data18
  });
}

exportToExcel() {
  const ws: xlsx.WorkSheet =   
  xlsx.utils.table_to_sheet(this.epltable.nativeElement);
  const wb: xlsx.WorkBook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  xlsx.writeFile(wb, 'epltable.xlsx');
 }
 logout(){
  this.cookieService.deleteAll();
 this.router.navigate(['/'])
}

}