<table>
    <tr>
        <td>No</td>
        <td>Waktu Mulai</td>
        <td>Waktu Selesai</td>
        <td>Durasi</td>
        <td>aktifitas</td>       
         <td>progres</td>
        <td>tindak lanjut</td>
        <td>lampiran</td>
        <td>simpan</td>
    </tr>
    <tr *ngFor="let nos of no">
        <td>{{nos}}</td>
        <td><input style ="width: 6em auto" type="text"  placeholder="Waktu mulai"> </td>
        <td><input style ="width: 6em auto" type="text"  placeholder="Waktu selesai"> </td>
        <td><input style ="width: 4em " type="number"  placeholder="Durasi"> </td>
        <td><input type="textarea"  placeholder="Aktifitas"> </td>
        <td><input type="text"  placeholder="Progres"> </td>
        <td><input type="text"  placeholder="Tindak lanjut"> </td>
        <td><input type="text"  placeholder="Lampiran"> </td>
        <td><input type="checkbox"  placeholder="Lampiran"> </td>
    </tr>
</table>