<div class="body">
    <div class="container">
      <div class="row">
        <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
          <div class="card card-signin my-5">
            <div class="card-body">
              <h5 class="card-title text-center">Sign In</h5>
                <div class="form-label-group">
                    Username
                  <input [(ngModel)]="username" type="text" id="inputEmail" class="form-control"  required autofocus>
                </div>
                <div class="form-label-group">
                    Password
                  <input [(ngModel)]="password" type="password" id="inputPassword" class="form-control"  required>
                </div>
  
               
                <button class="btn btn-lg btn-primary btn-block text-uppercase" (click)="login()" type="submit">Sign in</button>
                <hr class="my-4">
                {{error}}

            </div>
          </div>
        </div>
      </div>
    </div>
   </div> 
 
    