import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders, HttpParams} from '@angular/common/http';
import { Observable,throwError} from 'rxjs';
import { CookieService } from 'ngx-cookie-service';


const baseUrl = 'http://apiwfh.smaadzkiadt.sch.id/public/api/totaldurasi/';
const baseUrl1 = 'http://apiwfh.smaadzkiadt.sch.id/public/api/';
@Injectable({
  providedIn: 'root'
})
export class DataServiceService {

  constructor(private http: HttpClient, private cookieService:CookieService) { }

  getalluser(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl1+'user',{params});
  }

  getData19(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'19',{params});
  }
  getData20(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'20',{params});
  }
  getData21(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'21',{params});
  }
  getData22(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'22',{params});
  }
  getData23(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'23',{params});
  }
  getData24(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'24',{params});
  }
  getData25(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'25',{params});
  }
  getData26(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'26',{params});
  }
  getData27(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'27',{params});
  }
  getData28(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'28',{params});
  }
  getData29(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'29',{params});
  }
  getData30(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'30',{params});
  }
  getData31(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'31',{params});
  }
  getData1(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'01',{params});
  }
  getData2(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'02',{params});
  }
  getData3(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'03',{params});
  }
  getData4(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'04',{params});
  }
  getData5(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'05',{params});
  }
  getData6(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'06',{params});
  }
  getData7(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'07',{params});
  }
  getData8(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'08',{params});
  }
  getData9(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'09',{params});
  }
  getData10(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'10',{params});
  }
  getData11(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'11',{params});
  }
  getData12(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'12',{params});
  }
  getData13(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'13',{params});
  }
  getData14(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'14',{params});
  }
  getData15(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'15',{params});
  }
  getData16(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'16',{params});
  }
  getData17(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'17',{params});
  }
  getData18(): Observable<any> {
    const params = new HttpParams().append('token',this.cookieService.get('token'));

    return this.http.get(baseUrl+'18',{params});
  }
  

}


