import { Component, OnInit, Input,ViewChild, ElementRef } from '@angular/core';
import {WfhService} from '../service/wfh.service';
import { DataServiceService} from '../service/data-service.service';
import { CookieService } from 'ngx-cookie-service';
import {Router, ActivatedRoute} from "@angular/router";
import { faCheckCircle,faTimes } from '@fortawesome/free-solid-svg-icons';
import * as xlsx from 'xlsx';
@Component({
  selector: 'app-wfhadmin',
  templateUrl: './wfhadmin.component.html',
  styleUrls: ['./wfhadmin.component.css']
})
export class WfhadminComponent implements OnInit {
  faBookmark = faCheckCircle;faTimes=faTimes;
  @ViewChild('download', { static: false })
  epltable!: ElementRef;
  data: any;
  no:any=[];
  durasi: any;
  datas:any;
@Input() namauser:any;
  user: any;
  tgl: any;
  waktu_selesai: any;
  waktu_mulai: any;
  durasiwfh: any;
  aktifitas: any;
  lampiran: any;
  tindak_lanjut: any;
  bulanwfh: any;
  progres: any;
  id: any;
  constructor(private dataservice:DataServiceService, private route:ActivatedRoute, private router: Router ,private wfh:WfhService, private cookieService: CookieService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe( params =>
      this.namauser = params.get('nama'));
      this.no=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
    this.datawfhview(); 
    this.durasiwfhview();
    this. getUser()

  }

  getUser(){
    this.dataservice.getalluser().subscribe(user=>{
      this.user=user
    });
  }
  datawfhview(){
    const namauser ={nama:this.namauser};
    this.wfh.getData(namauser).subscribe(data=>{
      this.data=data
      })};
  
  durasiwfhview(){
    const namauser ={nama:this.namauser};
  
    this.wfh.getDurasi(namauser).subscribe(durasi=>{
  this.durasi=durasi;
  
  })};
  tambah(datas: any){
    const datapostwfh = {
      tgl:datas.tgl,
      nama:datas.nama,
      bulan:datas.bulan,
      periode:datas.periode,
      waktu_selesai:this.waktu_selesai,
  waktu_mulai:"",
  durasi:"",
  aktifitas:"",
  lampiran:"",
  tindak_lanjut:"",
 
    };
    this.wfh.postData(datapostwfh).subscribe(data=>{
      console.log(data);

      this.datawfhview();
      this.durasiwfhview();
     
    });
    
  }
  modal(datas:any){
    this.id=datas.id;
  }
  kurang(){
  
      this.wfh.hapusData(this.id).subscribe(Response=>{
      this.datawfhview();
      this.durasiwfhview();
      })
       }
  logout(){
    this.cookieService.deleteAll();
   this.router.navigate(['/'])
  }

  exportToExcel() {
  const ws: xlsx.WorkSheet =   
  xlsx.utils.table_to_sheet(this.epltable.nativeElement);
  const wb: xlsx.WorkBook = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
  xlsx.writeFile(wb,  this.namauser+'.xlsx');
 }

}
