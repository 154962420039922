import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { DataWfhComponent} from './data-wfh/data-wfh.component';
import { AdminComponent} from './admin/admin.component';
import { WfhadminComponent } from './wfhadmin/wfhadmin.component';
import { DurasiComponent } from './durasi/durasi.component';
import { KabagComponent } from './kabag/kabag.component';
import { KabagadminComponent } from './kabagadmin/kabagadmin.component';
import { DatadurasiComponent } from './datadurasi/datadurasi.component';
const routes: Routes = [
  {path:'', component:LoginComponent},
  {path:'home', component:HomeComponent},
  {path:'datawfh',component:DataWfhComponent},
  {path:'inputuser',component:AdminComponent},
  {path:'inputdata/:nama',component:WfhadminComponent},
  {path:'durasi',component:DurasiComponent},
  {path:'kabag',component:KabagComponent},
  {path:'datadurasi',component:DatadurasiComponent},

  {path:'kabag/:nama',component:KabagadminComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

