<div class="container">
  <div class="card-body">
<nav class="navbar navbar-expand-md navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a routerLink ="/kabag" class="nav-link  text-primary" >BERANDA</a>
      </li>
       <li> <a routerLink ="/home" (click)="logout()" class="nav-link text-danger" >LOGOUT</a></li>
    </ul>
  </nav>
  </div>
  </div>
  <div class="container">
  <div class="card">
    <div class="card-header">
   <b> Data Harian WFH </b>
      </div>
    <div class="card-body">
      <div class="table-responsive">

   <table class="table-striped">
     
      <tbody>
       
        <ng-container *ngFor = "let user of alluser">
        <tr  >
          <td >     <a routerLink="/kabag/{{user.nama}}" routerLinkActive="active">{{user.nama}}</a>    
          </td>
          <td class="px-2" *ngFor = "let periodes of periode|filter:user.nama; let i = index">{{periodes.tgl}} </td>
        </tr>
      </ng-container>
      </tbody>
   </table>
   </div>
   </div>
   </div>
  </div>