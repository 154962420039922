<div class="container">
  <div class="card-body">
    <nav class="navbar navbar-expand-md navbar-light">
        <ul class="navbar-nav">
          <li class="nav-item active">
            <a routerLink ="/home" class="nav-link  text-primary" >BERANDA</a>
          </li>
          <li class="nav-item">
            <a routerLink ="/inputuser" class="nav-link text-primary" >DATA USER</a>
          </li>
          <li class="nav-item">
            <a routerLink ="/durasi" class="nav-link text-primary" >DURASI WFH</a>
          </li>
          <li> <a routerLink ="/home" (click)="logout()" class="nav-link text-danger" >LOGOUT</a></li>
        </ul>
      </nav>
      </div>
</div>
 <div class="container">
   <div class="card">
     <div class="card-header">
      <div class="col-md-12 table-responsive" >
      <div style="padding-bottom: 10px;padding-top: 10px;">
      <div class="row">
      <div class="col-sm-12">
       <button (click)="exportToExcel()" class="btn btn-success">Export To Excel</button> 
      </div>
     </div>
    </div>
    </div>
  </div>
 
 <div class="card-body">
<table class="bordered"  #epltable id="epltable">
      <tr><td class="my-3"></td>
        <td class="px-2">20</td>
        <td class="px-2">21</td>
        <td class="px-2">22</td>
        <td class="px-2">23</td>
        <td class="px-2">24</td>
        <td class="px-2">26</td>
        <td class="px-2">27</td>
        <td class="px-2">28</td>
        <td class="px-2">29</td>
        <td class="px-2">30</td>
        <td class="px-2">31</td>
        <td class="px-2">1</td>
        <td class="px-2">2</td>
        <td class="px-2">3</td>
        <td class="px-2"> 4</td>
        <td class="px-2">5</td>
        <td class="px-2">6</td>
        <td class="px-2">7</td>
        <td class="px-2">8</td>
        <td class="px-2">9</td>
        <td class="px-2">10</td>
        <td class="px-2">11</td>
        <td class="px-2">12</td>
        <td class="px-2">13</td>
        <td class="px-2">14</td>
        <td class="px-2">15</td>
        <td class="px-2">16</td>
        <td class="px-2">17</td>
        <td class="px-2">18</td>
        <td class="px-2">19</td>





    </tr>
    <ng-container *ngFor="let users of user">

    <tr >
        <td>{{users.nama}}</td>
        <td ><span *ngFor="let datas20 of data20| filter: users.nama">{{datas20.durasi}}</span></td>
        <td ><span *ngFor="let datas21 of data21| filter: users.nama">{{datas21.durasi}}</span></td>
        <td ><span *ngFor="let datas22 of data22| filter: users.nama">{{datas22.durasi}}</span></td>
        <td ><span *ngFor="let datas23 of data23| filter: users.nama">{{datas23.durasi}}</span></td>
        <td ><span *ngFor="let datas24 of data24| filter: users.nama">{{datas24.durasi}}</span></td>
        <td ><span *ngFor="let datas25 of data25| filter: users.nama">{{datas25.durasi}}</span></td>
        <td ><span *ngFor="let datas26 of data26| filter: users.nama">{{datas26.durasi}}</span></td>
        <td ><span *ngFor="let datas27 of data27| filter: users.nama ">{{datas27.durasi}}</span></td>
        <td ><span *ngFor="let datas28 of data28| filter: users.nama">{{datas28.durasi}}</span></td>
        <td ><span *ngFor="let datas29 of data29| filter: users.nama">{{datas29.durasi}}</span></td>
        <td ><span *ngFor="let datas30 of data30| filter: users.nama">{{datas30.durasi}}</span></td>
        <td ><span *ngFor="let datas31 of data31| filter: users.nama">{{datas31.durasi}}</span></td>
        <td ><span *ngFor="let datas1 of data1| filter: users.nama">{{datas1.durasi}}</span></td>
        <td ><span *ngFor="let datas2 of data2| filter: users.nama">{{datas2.durasi}}</span></td>
        <td ><span *ngFor="let datas3 of data3| filter: users.nama">{{datas3.durasi}}</span></td>
        <td ><span *ngFor="let datas4 of data4| filter: users.nama ">{{datas4.durasi}}</span></td>
        <td ><span *ngFor="let datas5 of data5| filter: users.nama">{{datas5.durasi}}</span></td>
        <td ><span *ngFor="let datas6 of data6| filter: users.nama">{{datas6.durasi}}</span></td>
        <td ><span *ngFor="let datas7 of data7| filter: users.nama">{{datas7.durasi}}</span></td>
        <td ><span *ngFor="let datas8 of data8| filter: users.nama">{{datas8.durasi}}</span></td>
        <td ><span *ngFor="let datas9 of data9| filter: users.nama">{{datas9.durasi}}</span></td>
        <td ><span *ngFor="let datas10 of data10| filter: users.nama">{{datas10.durasi}}</span></td>
        <td ><span *ngFor="let datas11 of data11| filter: users.nama">{{datas11.durasi}}</span></td>
        <td ><span *ngFor="let datas12 of data12| filter: users.nama">{{datas12.durasi}}</span></td>
        <td ><span *ngFor="let datas13 of data13| filter: users.nama">{{datas13.durasi}}</span></td>
        <td ><span *ngFor="let datas14 of data14| filter: users.nama">{{datas14.durasi}}</span></td>
        <td ><span *ngFor="let datas15 of data15| filter: users.nama">{{datas15.durasi}}</span></td>
        <td ><span *ngFor="let datas16 of data16| filter: users.nama">{{datas16.durasi}}</span></td>
        <td ><span *ngFor="let datas17 of data17| filter: users.nama">{{datas17.durasi}}</span></td>
        <td ><span *ngFor="let datas18 of data18| filter: users.nama">{{datas18.durasi}}</span></td>
        <td ><span *ngFor="let datas19 of data19| filter: users.nama ">{{datas19.durasi}}</span></td>

    </tr>
    </ng-container>
</table>
</div>
</div>