import { Component, OnInit,Input } from '@angular/core';
import {WfhService} from '../service/wfh.service';
import { CookieService } from 'ngx-cookie-service';
import {Router} from "@angular/router";
import { faEdit,faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-data-wfh',
  templateUrl: './data-wfh.component.html',
  styleUrls: ['./data-wfh.component.css']
})
export class DataWfhComponent implements OnInit {
  faEdit = faEdit;faTimes=faTimes;

data:any=[];durasi:any=[]; datas:any=[];datanamauser:any;
tabel:any; i:any;buttonhide:any; mulai:any;selesai:any;tgl:any;
datapostwfh:any=[];tabel19:any;tabel20:any;
  waktu_mulai: any;
  nama: any;
  aktifitas: any;
  lampiran: any;
  tindak_lanjut: any;
  waktu_selesai: any;
  tglwfh: any;
  dataview: any;
  durasiview: any;namauser:any;
  bulanwfh: any;
  durasiwfh: any;
  progres: any;
  idperiode: any;
  number:any=[];
  bulan:any=[];
  tersimpan:boolean=true;
  tglupdate: any;
  blnupdate: any;
  selesaiupdate: any;
  mulaiupdate: any;
  durasiupdate: any;
  aktifitasupdate: any;
  lampiranupdate: any;
  tindakupdate: any;
  progresupdate: any;
  id: any;
  constructor(private router: Router ,private wfh:WfhService, private cookieService: CookieService) { }

  ngOnInit(): void {
    
    this.namauser=this.cookieService.get('nama');
    this.datawfhview(); 
    this.durasiwfhview();
    this.number=["01","02","03","04","05","06","07","08","09","10","11","12","13",
    "14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","20'"];
    this.bulan=["Januari","Februari","Maret","April","Mei","Juni","Juli","Agustus","September","Oktober","November","Desember"]
  }
 edit(){

 }
datawfhview(){
  const namauser ={nama:this.namauser};
  this.wfh.getData(namauser).subscribe(datanamauser=>{
    console.log(datanamauser);
    this.datanamauser=datanamauser
    })};

durasiwfhview(){
  const namauser ={nama:this.namauser};

  this.wfh.getDurasi(namauser).subscribe(durasi=>{
    console.log(durasi);
this.durasi=durasi;

})};
hapusdata(datas: { id: string; }){
this.wfh.hapusData(datas.id).subscribe(Response=>{
this.datawfhview();
this.durasiwfhview();
})
}

showtabel(){
    this.tabel=true;
    this.buttonhide=false;
}
hidetabel(){
  this.tabel=false;
  this.buttonhide=true;
}
datawfh(){
const datapostwfh = {
  tgl:this.tglwfh,
  nama:this.namauser,
  waktu_selesai:this.waktu_selesai,
  waktu_mulai:this.waktu_mulai,
  durasi:this.durasiwfh,
  aktifitas:this.aktifitas,
  lampiran:this.lampiran,
  tindak_lanjut:this.tindak_lanjut,
  bulan:this.bulanwfh,
  progres:this.progres,
};

this.wfh.postData(datapostwfh).subscribe(data=>{
  console.log(data);
  this.data = data['message'];
  this.tersimpan = true; 
  this.datawfhview();
  this.durasiwfhview();
  setTimeout( () => {
    console.log('hide');
    this.tersimpan = false; 
  }, 2000);
});

//this.wfh.postTgl(datatgl).subscribe(datatg=>{
  //console.log(datatgl)});
}
updatewfh(){
const datawfh={
  nama:this.namauser,
 tgl: this.tglupdate,
 bulan: this.blnupdate,
 waktu_selesai: this.selesaiupdate,
 waktu_mulai: this.mulaiupdate,
 durasi: this.durasiupdate,
 aktifitas: this.aktifitasupdate,
 lampiran: this.lampiranupdate,
 tindak_lanjut: this.tindakupdate,
 progres: this.progresupdate,
}

this.wfh.updateData(this.id,datawfh).subscribe(data=>{
  
  this.data = data['message'];
  this.tersimpan = true; 
  this.datawfhview();
  this.durasiwfhview();
  setTimeout( () => {
    console.log('hide');
    this.tersimpan = false; 
  }, 2000);
});
}
updateData(datas: any){
  this.id = datas.id;
  this.tglupdate = datas.tgl;
  this.blnupdate = datas.bulan;
  this.selesaiupdate = datas.waktu_selesai;
  this.mulaiupdate = datas.waktu_mulai;
  this.durasiupdate = datas.durasi;
  this.aktifitasupdate = datas.aktifitas;
  this.lampiranupdate = datas.lampiran;
  this.tindakupdate = datas.tindak_lanjut;
  this.progresupdate = datas.progres;
  
}
logout(){
  this.cookieService.deleteAll();
 this.router.navigate(['/'])
}


}