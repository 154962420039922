import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { HttpClientModule} from '@angular/common/http';
import { DataWfhComponent } from './data-wfh/data-wfh.component';
import {NgPipesModule} from 'ngx-pipes';
import { CookieService } from 'ngx-cookie-service';
import { CommonModule } from '@angular/common';
import { AdminComponent } from './admin/admin.component';
import { WfhadminComponent } from './wfhadmin/wfhadmin.component';  
import { ExcelService } from './service/excel.service';
import { DurasiComponent } from './durasi/durasi.component';
import { KabagComponent } from './kabag/kabag.component';
import { KabagadminComponent } from './kabagadmin/kabagadmin.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { DatadurasiComponent } from './datadurasi/datadurasi.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    DataWfhComponent,
    AdminComponent,
    WfhadminComponent,
    DurasiComponent,
    KabagComponent,
    KabagadminComponent,
    DatadurasiComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    Ng2SearchPipeModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgPipesModule,
    FormsModule,
    CommonModule,
    FontAwesomeModule

    
  ],
  providers: [CookieService,ExcelService],
  bootstrap: [AppComponent]
})
export class AppModule {
  
 }
