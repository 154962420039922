<div class="container">
  <div class="card-body">
<nav class="navbar navbar-expand-md navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item active">
        <a routerLink ="/home" class="nav-link  text-primary" >BERANDA</a>
      </li>
      <li class="nav-item">
        <a routerLink ="/inputuser" class="nav-link text-primary" >DATA USER</a>
      </li>
      <li class="nav-item">
        <a routerLink ="/durasi" class="nav-link text-primary" >DURASI WFH</a>
      </li>
      <li> <a routerLink ="/home" (click)="logout()" class="nav-link text-danger" >LOGOUT</a></li>
    </ul>
  </nav>
  </div>
  </div>
    <div class="container">
    <div class="card">
      <div class="card-header">
      <b>Input User </b>
      </div>
         <div class="card-body">
          <div class="form-group">
            <label for="usr">Nama:</label>
            <input [(ngModel)]="nama" type="text" class="form-control" id="usr">
          </div>
          <div class="form-group">
            <label for="usr">Username:</label>
            <input [(ngModel)]="username" type="text" class="form-control" id="usr">
          </div>
          <div class="form-group">
            <label for="usr">Password:</label>
            <input [(ngModel)]="password" type="text" class="form-control" id="usr">
          </div>
          <div class="form-group">
            <label for="usr">Jenis Karya:</label>
            <input [(ngModel)]="karya" type="text" class="form-control" id="usr">
          </div>
          <div class="form-group">
            <label for="sel1">Akses:</label>
            <select [(ngModel)]="akses" class="form-control" id="sel1">
              <option>user</option>
              <option>kabag</option>
              <option>superadmin</option>
            </select>
          </div>
          <button (click)="saveuser()" class="btn btn-success">simpan</button>
      </div>
      {{pesan}}
      <br>
      <div class="card">
        <div class="card-header">
      <b>DAFTAR USER</b>
        </div>
        <div class="card-body">
      <table class="table-responsive">
        <tr *ngFor="let users of user">
          <td class="px-3">{{users.nama}}</td>
          <td class="px-3">{{users.akses}}</td>
          <td class="px-3"><button class="btn btn-danger"  (click)="hapusdata(users)">Hapus</button></td>

        </tr>
      </table>
      </div>
      </div>
      <div class="card-header">
        <b>input periode WFH</b>
      </div>
        <table class="table">
            <tr *ngFor ="let periodes of periode">
              <td>id: {{periodes.periode}}</td>
              <td>Periode: {{periodes.blnperiode}}</td>
              <td></td>
            </tr>
              <td> <div class="form-group">
                <input [(ngModel)]="idperiode" type="text" class="form-control" id="usr">
              </div>
            </td>
              <td>
                <div class="form-group">
                  <input [(ngModel)]="blnperiode" type="text" class="form-control" id="usr">
                </div>
              </td>
              <td><button class="btn btn-danger" (click)="updateperiode(periode)">masukkan periode</button></td>
            <tr>

            </tr>
        </table>
      </div>
    </div>