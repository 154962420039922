import { Component, OnInit } from '@angular/core';
import {WfhService} from '../service/wfh.service';
import { CookieService } from 'ngx-cookie-service';
import {Router} from "@angular/router";
@Component({
  selector: 'app-kabag',
  templateUrl: './kabag.component.html',
  styleUrls: ['./kabag.component.css']
})
export class KabagComponent implements OnInit {
  data:any=[]; user:any=[];periode:any=[];
  alluser: any;
  constructor(private router:Router, private wfh:WfhService, private cookieService: CookieService) { }
  

  
  ngOnInit(): void {
    this.gperiode();
    this.getuser();
  }
  gperiode(){
    this.wfh.getTgl().subscribe(periode=>{
     this.periode=periode;
     console.log(periode)
    })
  }
getuser(){
  this.wfh.getalluser().subscribe(user=>{
    this.alluser=user;
    console.log(user)
  })
}
  datawfhview(){this.wfh.getallData().subscribe(data=>{
    console.log(data),
      this.data=data
      })};

      logout(){
        this.cookieService.deleteAll();
       this.router.navigate(['/'])
      }

}
